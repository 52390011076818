import React from "react";
import "./style.scss";

const TextSection = () => {
  return (
    <section className="text-section">
      <div className="container">
        <div className="text">
          <p>
            Trained by some of the best yoga teachers from the USA and India,
            Mahrukh has honed her skills and immersed herself in the ancient
            wisdom of yoga. Her unique blend of cultural influences from
            Pakistan, UAE, USA and India enriches her teachings, creating a
            harmonious experience that transcends borders and unites hearts.
          </p>
        </div>
        <div className="text">
          <p>
            Mahrukh’s teaching style is a harmonious blend of physical
            challenge, surrender, spiritual guidance, and emotional support. She
            encourages her students to embrace their uniqueness and
            vulnerabilities, fostering self-love and acceptance along the way.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TextSection;
