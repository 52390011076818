import { retreatCardImg1, retreatCardImg2 } from "../../assets/images/images";

export const retreatCardInfo = [
  {
    id: 1,
    cardTitle: "A Journey Inward. Sept 22-25",
    cardDesc: "A Soulful Journey to Inner Harmony Yoga and Meditation Retreat",
    cardImg: retreatCardImg1,
    checkIn: "Sept 22, 2024 @ 4pm",
    checkOut: "Sept 25, 2024 @ 12pm",
    hashId: "journeyInward",
    discount: {
      active: true,
      percentage: 10,
      start: "2024-06-23T00:00:00Z",
      end: "2024-08-31T23:59:59Z",
    },
    prices: [
      {
        title: "Shared room + shared bathroom 6 rooms",
        description: "(includes all part of the retreat + all meals)",
        detail: {
          title: "Shared Room + Shared Bathroom (Single Occupancy)",
          description:
            "Includes all parts of the retreat (Welcome Bags, Yoga, Meditation, Breathwork, Sound Healing, All Meals, Chiropractic Adjustments, Dry Needling/Acupuncture, Community Circles, Workshops, Sound Bath, Cacao Ceremony, Pickup and Drop off from Satellite Parking )",
          info: "",
        },
        amounts: [
          {
            title: "Single occupancy",
            amount: 1200,
          },
        ],
      },
      {
        title: "Private King Room  + private bathroom 8 rooms",
        doubleText: "for both participants",
        description:
          "(includes all part of the retreat + All Meals + 30 minute Photoshoot + Massage session)",
        detail: {
          title:
            "Private King Room + Private Bathroom (Single or Double Occupancy)",
          description:
            "Includes all parts of the retreat (Welcome Bags, Yoga, Meditation, Breathwork, Sound Healing, All Meals, Chiropractic Adjustments, Dry Needling/Acupuncture, Community Circles, Workshops, Sound Bath, Cacao Ceremony, Pickup and Drop off from Satellite Parking)",
          info: "Plus: 30 Minutes Photoshoot, 60 Minute Massage",
        },
        amounts: [
          {
            title: "Single occupancy",
            amount: 1400,
          },
          {
            title: "Double occupancy",
            amount: 2650,
          },
        ],
      },
      {
        title: "VIP beachfront Private king + private bathroom 2 rooms",
        doubleText: "for both participants",
        description:
          "(includes all part of the retreat + All Meals + 30 minute Photoshoot + One on one partner Yoga session with retreat host + One hour massage)",
        detail: {
          title:
            "VIP Beachfront Private King Room + Private Bathroom (Double Occupancy)",
          description:
            "Includes all parts of the retreat (Welcome Bags, Yoga, Meditation, Breathwork, Sound Healing, All Meals, Chiropractic Adjustments, Dry Needling/Acupuncture, Community Circles, Workshops, Sound Bath, Cacao Ceremony, Pickup and Drop off from Satellite Parking)",
          info: "Plus: 30 Minutes Photoshoot, 60 Minute Massage, 60 Minute One on One Partner/Solo Yoga Session with Retreat Facilitator",
        },
        amounts: [
          {
            title: "Double occupancy",
            amount: 3000,
          },
        ],
      },
      // {
      //   title: "Private king + private bathroom (level 3) 4 rooms",
      //   description:
      //     "(includes all part of the retreat + All Meals + 30 minute Photoshoot + One on one partner Yoga session with retreat host + One hour massage)",
      //   detail: {
      //     title: "",
      //     description: "",
      //     info: "",
      //   },
      //   amounts: [
      //     {
      //       title: "Single occupancy",
      //       amount: 1850,
      //     },
      //     {
      //       title: "Double occupancy",
      //       amount: 3400,
      //     },
      //   ],
      // },
    ],
  },
  {
    id: 2,
    cardTitle: "The Body Holiday Sept 25-28",
    cardDesc: "Sacred Rest and Reset Yoga and Meditation Retreat",
    cardImg: retreatCardImg2,
    checkIn: "Sept 25, 2024 @ 4pm",
    checkOut: "Sept 28, 2024 @ 12pm",
    hashId: "bodyHoliday",
    discount: {
      active: true,
      percentage: 10,
      start: "2024-06-23T00:00:00Z",
      end: "2024-08-31T23:59:59Z",
    },
    prices: [
      {
        title: "Shared room + shared bathroom 6 rooms",
        description: "(includes all part of the retreat + all meals)",
        detail: {
          title: "Shared Room + Shared Bathroom (Single Occupancy)",
          description:
            "Includes all parts of the retreat (Welcome Bags, Yoga, Meditation, Breathwork, Sound Healing, All Meals, Chiropractic Adjustments, Dry Needling/Acupuncture, Community Circles, Workshops, Sound Bath, Cacao Ceremony, Pickup and Drop off from Satellite Parking )",
          info: "",
        },
        amounts: [
          {
            title: "Single occupancy",
            amount: 1200,
          },
        ],
      },
      {
        title: "Private King Room + private bathroom 8 rooms",
        doubleText: "for both participants",
        description:
          "(includes all part of the retreat + All Meals + 30 minute Photoshoot + Massage session)",
        detail: {
          title:
            "Private King Room + Private Bathroom (Single or Double Occupancy)",
          description:
            "Includes all parts of the retreat (Welcome Bags, Yoga, Meditation, Breathwork, Sound Healing, All Meals, Chiropractic Adjustments, Dry Needling/Acupuncture, Community Circles, Workshops, Sound Bath, Cacao Ceremony, Pickup and Drop off from Satellite Parking)",
          info: "Plus: 30 Minutes Photoshoot, 60 Minute Massage",
        },
        amounts: [
          {
            title: "Single occupancy",
            amount: 1400,
          },
          {
            title: "Double occupancy",
            amount: 2650,
          },
        ],
      },
      {
        title: "VIP beachfront Private king + private bathroom 2 rooms",
        doubleText: "for both participants",
        description:
          "(includes all part of the retreat + All Meals + 30 minute Photoshoot + One on one partner Yoga session with retreat host + One hour massage)",
        detail: {
          title:
            "VIP Beachfront Private King Room + Private Bathroom (Double Occupancy)",
          description:
            "Includes all parts of the retreat (Welcome Bags, Yoga, Meditation, Breathwork, Sound Healing, All Meals, Chiropractic Adjustments, Dry Needling/Acupuncture, Community Circles, Workshops, Sound Bath, Cacao Ceremony, Pickup and Drop off from Satellite Parking)",
          info: "Plus: 30 Minutes Photoshoot, 60 Minute Massage, 60 Minute One on One Partner/Solo Yoga Session with Retreat Facilitator",
        },
        amounts: [
          {
            title: "Double occupancy",
            amount: 3000,
          },
        ],
      },
      // {
      //   title: "Private king + private bathroom 4 rooms",
      //   description:
      //     "(includes all part of the retreat + All Meals + 30 minute Photoshoot + One on one partner Yoga session with retreat host + One hour massage)",
      //   amounts: [
      //     {
      //       title: "Single occupancy",
      //       amount: 1850,
      //     },
      //     {
      //       title: "Double occupancy",
      //       amount: 3400,
      //     },
      //   ],
      // },
    ],
  },
];
